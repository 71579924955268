<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="allCardList">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvHeader}}
            </h4>
          </template>
        </iq-card>
        <iq-card class="p-2">
          <!-- Backend Search -->
            <input class="form-control"
            v-model="whereFilter.search_param"
            type="search"
            @input="searchUserWithParams"
            :placeholder="cvSearchText"
          /><!-- Backend Search -->
        </iq-card>
        <iq-card class="p-2">
          <b-col class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="iq-card iq-card-block blog blog-detail p-2" v-for="(item, index) in itemList" :key="index">
              <h4 style="text-transform: capitalize;" class="pl-3 p-0 my-2">{{item.title}}</h4>
              <div class="blog-description ml-3" >
                <span class="vhtmlTag vueHtmlParent">
                  {{ item.description }}&nbsp;&nbsp;<i v-if="item.url" class="fa-solid fa-arrow-right primary-color pointer" @click="gotoURL(item)"></i>
                </span><br>
                <span v-if="item.attr">
                  <span v-for="(attrItem, cindex) in item.attr.split(',')" :key="cindex">
                    <b-badge variant="primary" class="mr-2" v-on:click="setFilterParams(attrItem)"><span>&nbsp;{{ attrItem }}</span></b-badge>
                  </span>
                </span>
                <span v-if="item.class">
                  <span v-for="(attrItem, cindex) in item.class.split(',')" :key="cindex">
                    <b-badge variant="primary" class="mr-2" v-on:click="setFilterParams(attrItem)"><span>Class&nbsp;{{ attrItem }}</span></b-badge>
                  </span>
                </span>
              </div>
            </div>
          </b-col>
      </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { socialvue } from "../../../config/pluginInit.js"
import { Resources } from "../../../FackApi/api/Resources.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"

export default {
  name: "Resources",
  data () {
    return {
      itemList: [],
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Resources",
      cvHeader: "",
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList(),
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      name: "",
      whereFilter: {
        search_param: ""
      }
    }
  },
  components: {
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    socialvue.index()
    this.name = this.$route.params.name
    this.cvHeader = this.name
    this.getResourceList()
  },
  methods: {
    async setFilterParams (attrItem) {
      this.whereFilter.search_param = attrItem
      this.getResourceList()
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        await this.getResourceList()
      }
      else if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.currentBatchNo = 1
      await this.getResourceList()
    },
    /**
     * get Resource List
     */
    async getResourceList (loadViaLoadMore = false) {
      try {
        const resourceDataResp = await Resources.resourceGPathRoadmapExtraList(this, this.whereFilter)
        if (resourceDataResp && !resourceDataResp.resp_status) {
          this.toastMsg = resourceDataResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        else {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.itemList = [ ...resourceDataResp.resp_data ]
          }
          else {
            this.itemList = [...resourceDataResp.resp_data]
          }
        }
      }
      catch (err) {
        console.error("Exception in getResourceList and err: ", err)
      }
    },
    /**
     * goto URL
     */
    gotoURL (item) {
      window.open(item.url, "_blank")
    }
  }
}
</script>
<style>
.iq-card-header{
  display:none
}
.ul-list{
  padding: 0px !important;
  line-height: normal !important;
  padding-left: 15px !important;
  list-style: disc;
}
.badge span{
  word-break: break-all;
  white-space: break-spaces;
}
</style>
