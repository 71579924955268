
import request from "../../Utils/curl"
import Utility from "../../Utils/utility"
let Resources = {
  /**
   * resource Scholarships Get
   */
  async resourceScholarshipsGet (context) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try {
      let postData = new FormData()

      return await request.curl(context, "resource_scholarships_list", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at resourceScholarshipsGet() and Exception:", err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },
  /**
   * resource gpath_roadmap_extra list
   */
  async resourceGPathRoadmapExtraList (context, filter = {}) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try {
      let postData = new FormData()
      postData.append("name", context.name)
      postData.append("filter", JSON.stringify(filter))
      return await request.curl(context, "resource_gpath_roadmap_extra_list", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at resourceGPathRoadmapExtraList() and Exception:", err.message)
    }
    finally {
      Utility.showLoader = false
    }
  }
}

export {
  Resources
}
